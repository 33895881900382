<template>
  <div class="goodsdetail_container">
    <div v-if="hasData" class="content">
      <div class="top">
        <div class="left">
          <div>
            <div class="img_box">
              <img :src="bigImg" alt="" />
            </div>
            <div v-show="topShow" class="img_top" :style="topStyle"></div>
            <div class="maskTop" @mouseenter="enterHandler" @mousemove="moveHandler" @mouseout="outHandler"></div>
            <div v-show="rShow" class="img_right_big">
              <img :style="r_img" class="rightImg" :src="bigImg" alt="" />
            </div>
          </div>

          <div class="thumb_wrap">
            <div class="thumb_box">
              <div class="item" v-for="item in info.thumb" :key="item.url" @mouseenter="mouseenter(item)">
                <img :src="item.url" alt="" />
              </div>
            </div>
          </div>
          <div class="tip">温馨提示：部分商品包装更换频繁，如货品与图片不一致， 请以收到的商品实物为准</div>
        </div>
        <div class="right">
          <div class="title">{{ info.title }}</div>
          <div v-if="info.time_end" class="time-box">距离活动结束：{{ djs }}</div>
          <div class="pr_box" style="padding-top: 5px;padding-bottom: 5px;">
            价格：<span class="price">
              ￥<span>{{ info.price }}</span> <span class="huaxian" v-if="info.line_price">￥{{ info.line_price }}</span>
            </span>
          </div>
          <div class="retail_price c9" v-if="info.retail_price">建议零售价：<span class="c0">￥{{ info.retail_price }}</span></div>
          <div class="tag-box">
            <div class="flex_row mb_10" v-for="item in info.tags" :key="item.title">
              <div :style="`background-color: #${item.color}; border: 1px solid #${item.color};`" class="tag mr_10 p_title">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="active-red" v-if="info.show_memo">{{ info.show_memo }}</div>
          <div class="flex_row mb_10" v-if="info.activity && info.activity.length">
            <p class="mr_20 p_title">全场满减</p>
            <div v-for="item in info.activity" :key="item.id" class="mr_10">
              {{ item.content }}
            </div>
          </div>
          <div class="flex_row mb_10" v-if="info.lqyhj && info.lqyhj.length">
            <p class="mr_20 p_title">优惠券</p>
            <div v-for="item in info.lqyhj" :key="item.id" class="mr_10">满{{ item.zdje_str }}优惠{{ item.hbje_str }}</div>
          </div>
          <div class="flex_row mb_10" v-if="info.activity_tag && info.activity_tag.content">
            <p class="mr_20 p_title" :style="`color: #${info.activity_tag.color}; border: 1px solid #${info.activity_tag.color};`">
              {{ info.activity_tag.title }}
            </p>
            <div class="mr_10">
              {{ info.activity_tag.content }}
            </div>
          </div>
          <div class="detail">
            <div class="flex">
              <div>
                库存数量：<span>{{ info.kc_str }}</span>
              </div>
              <div v-if="info.grossmargin">
                毛利率：<span>{{ info.grossmargin }}</span>
              </div>
            </div>

            <div class="flex">
              <div>
                商品规格：<span>{{ info.sku }}</span>
              </div>
              <div>
                批准文号：<span>{{ info.pzwh }}</span>
              </div>
            </div>
            <div class="flex">
              <div>
                件 装 量 ：<span>{{ info.jzl }}</span>
              </div>
              <div>
                中 包 装 ：<span>{{ info.zbz }}</span>
              </div>
            </div>
            <div>
              生产企业：<span>{{ info.scqy }}</span>
            </div>
            <div class="flex">
              <div>
                国 家 码 ：<span>{{ info.gjbm }}</span>
              </div>
              <div>
                有 效 期 ：<span>{{ info.validity_date }}</span>
                <!-- <span style="color: red">{{ info.stock_str }}</span> -->
              </div>
            </div>
          </div>
          <div class="pr_box">
            购买数量：
            <div class="number_box">
              <div class="numleft c3 c_p" @click="handleMinus">
                <i class="el-icon-minus"></i>
              </div>
              <!-- <div class="num">{{ info.cart_num }}</div> -->
              <div class="num">
                <el-input v-model="number" @blur="blur(info)"></el-input>
              </div>
              <div class="numright c3 c_p" @click="handlePlus">
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </div>
          <div class="flex_row mt_20">
            <div class="btn red-btn mr_20" @click="handleCollect">{{ info.is_collected == 101 ? "已收藏" : "收藏" }}</div>
            <div class="btn green-btn mr_20" @click="addToCart" v-if="info.btn1_show == 101">{{ info.btn1_str }}</div>
            <div class="btn mr_20" @click="handleBuy" v-if="info.btn2_show == 101">{{ info.btn2_str }}</div>
            <div class="btn mr_20" @click="applyGoods" v-if="info.btn3_show == 101">{{ info.btn3_str }}</div>
            <div :class="['blue-btn', 'mr_20', postFlag ? 'btn' : 'gray-btn']" @click="lookLicense" v-if="info.erpid">药品资质</div>
          </div>
        </div>
      </div>
      <div class="main_wrap">
        <div class="hot">
          <div class="title flex_c_c">本类热销商品</div>
          <div class="goods_wrap">
            <div class="flex_column" v-for="item in hotGoods" :key="item.id">
              <div class="goodsimg" @click="toDetail(item)">
                <img :src="item.logo" alt="" />
              </div>
              <div class="c3" style="cursor: pointer" @click="toDetail(item)">{{ item.title }}</div>
              <!-- <div class="c9">
                规格：<span>{{ item.sku }}</span>
              </div> -->
              <div class="c9">
                成交量：<span>{{ item.xl }}</span>
              </div>
              <div class="c9">
                会员价：<span>￥{{ item.price }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="main">
          <div class="tab">
            <div class="item" :class="current === i ? 'active' : ''" @click="current = i" v-for="(item, i) in tab" :key="item.name">
              {{ item.name }}
            </div>
          </div>
          <div class="cont">
            <div v-if="current == 0" v-html="info.contents"></div>
            <div v-if="current == 1" v-html="info.phsm" />
            <div v-if="current == 2" v-html="info.aqys"></div>
            <div v-if="current == 3" v-html="info.zzry"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content no-goods-box">
      <img src="@/assets/img/goods/no_goods.png" style="height: 500px; width: 500px" alt="" />
    </div>
    <!-- 申请开通商品弹窗 -->
    <el-dialog title="请输入申请资质" class="pay-pop" :visible.sync="openGoods" width="30%">
      <el-form :model="goodsForm" :rules="rules" ref="goodsForm" label-width="100px">
        <el-form-item label="申请人" prop="lxr">
          <el-input v-model="goodsForm.lxr" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="申请电话" prop="lxdh">
          <el-input v-model="goodsForm.lxdh" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('goodsForm')">取 消</el-button>
        <el-button type="primary" @click="applyConfirm('goodsForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 药品资质弹框 -->
    <el-dialog title="药品资质" class="pay-pop" :visible.sync="openLicense" width="30%">
      <div class="pop-list" v-for="item in license" :key="item.drug_id" @click="lookDetail(item)">
        <span><i class="el-icon-s-claim" color="#FF0000"></i>{{ item.qualification_type_name }}</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validatePhone = (rule, value, callback) => {
      const reg = /^1[3-9]\d{9}$/;
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      postFlag: true,
      hasData: true,
      license: [],
      current: 0,
      djs: "",
      tab: [{ name: "说明书" }, { name: "配货说明" }, { name: "安全隐私" }, { name: "资质荣誉" }],
      info: {},
      bigImg: "",
      hotGoods: [],
      number: 1,
      topStyle: { transform: "" },
      r_img: {},
      topShow: false,
      rShow: false,
      goods_id: "",
      openGoods: false,
      openLicense: false,
      goodsForm: {
        id: "",
        lxr: "",
        lxdh: "",
      },
      rules: {
        lxr: [{ required: true, message: "请输入申请人名称", trigger: "blur" }],
        lxdh: [{ required: true, message: "请输入符合规范的电话号码", trigger: "blur", validator: validatePhone }],
      },
    };
  },
  created() {
    // document.title = '商品详情';
    //解析query value值
    let param = this.$route.query;

    if (this.$route.query.id > 0) {
      this.goods_id = this.$route.query.id;
      this.getdata();
    } else if (param != "") {
      let queryVal = param.value;
      queryVal = queryVal.replace("?", "");
      let queryValArr = queryVal.split("&");
      queryValArr.forEach((item) => {
        let arr = item.split("=");
        if (arr[0] == "id") {
          this.goods_id = arr[1];
          this.getdata();
        } else if (arr[0] == "title") {
          document.title = arr[1];
        }
      });
    } else {
      this.$message.error("未上传商品标识");
    }
  },
  mounted() {
    window.addEventListener('onmessageWS', this.getSocketData)
    setInterval(() => {
      if (this.info.time_end) {
        var rightTime = Math.floor((this.info.time_end * 1000 - Date.now()) / 1000);
        if (rightTime > 0) {
          var dd = Math.floor(rightTime / 60 / 60 / 24);
          var hh = Math.floor((rightTime / 60 / 60) % 24);
          var mm = Math.floor((rightTime / 60) % 60);
          var ss = Math.floor(rightTime % 60);
        }
        this.djs = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
      }
    }, 1000);
  },
  methods: {
    getSocketData (res) {
		console.log(res.detail);
	},
    InitTime(endtime) {
      var dd,
        hh,
        mm,
        ss = null;
      var time = Math.floor((endtime * 1000 - Date.now()) / 1000);
      if (time <= 0) {
        return "结束";
      } else {
        dd = Math.floor(time / 60 / 60 / 24);
        hh = Math.floor((time / 60 / 60) % 24);
        mm = Math.floor((time / 60) % 60);
        ss = Math.floor(time % 60);
        var str = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
        return str;
      }
    },
    toDetail(data) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id: data.id },
      });
      window.open(href, "_blank");
    },
    getdata() {
      this.$api("goods.getGoodsDel", { id: this.goods_id }).then((res) => {
        if (res.code == 200) {
          this.hasData = true;
          this.info = res.data;
          this.number = res.data.min_num;
          if (res.data.time_end) {
            this.djs = this.InitTime(res.data.time_end);
          }
          this.bigImg = this.info.thumb[0].url;
          this.getHotGoods(res.data.id);
          document.title = res.data.title;
        }
        if (res.code == 201) {
          this.hasData = false;
        }
      });
    },
    getHotGoods(id) {
      this.$api("goods.getHotGoods", {
        id: id,
        page: 1,
        page_size: 3,
      }).then((res) => {
        this.hotGoods = res.data;
      });
    },
    mouseenter(item) {
      this.bigImg = item.url;
    },
    handleCollect() {
      if (this.info.is_collected == 101) {
        this.$api("goods.delCollect", { id: this.info.id }).then(() => {
          this.$message.success("取消收藏成功");
          this.info.is_collected = 102;
        });
      } else {
        this.$api("goods.addCollect", { id: this.info.id }).then(() => {
          this.$message.success("收藏成功");
          this.info.is_collected = 101;
        });
      }
    },
    addToCart() {
      if (this.info.show_cart == 101) {
        let p = {
          id: this.info.id,
          number: this.number,
        };
        this.$api("goods.addCart", p).then(() => {
          this.$store.dispatch("getBadge");
        });
      } else {
        this.$message.warning("该商品暂时不能购买！");
      }
    },
    handleBuy() {
      if (this.info.show_cart == 101) {
        this.$api("cart.getAttr").then((res) => {
          let buyAttr = res.data;
          if (Number(this.number) * Number(this.info.price) < Number(buyAttr.qfje)) {
            console.log(1111);
            this.$message.warning(`单笔订单需满${buyAttr.qfje}才能进行结算`);
            return;
          } else {
            console.log(2222);
            let p = {
              id: this.goods_id,
              number: this.number,
            };
            this.$api("goods.addCart", p).then((res) => {
              if (res.code == 200) {
                this.$store.dispatch("getBadge");
                this.$api("cart.submitCart", { goods: [{ id: this.goods_id, number: this.number }] }).then((r) => {
                  this.$router.push({
                    name: "CartCheck",
                    params: { ddbh: r.data.ddbh },
                  });
                });
              }
            });
          }
        });
      } else {
        this.$message.warning("该商品暂时不能购买！");
      }
    },
    handleMinus() {
      let { btn1_show, step, min_num } = this.info;
      if (btn1_show == 102) return;
      if (Number(min_num) >= Number(this.number)) {
        this.$message.error("不能再减少了");
        return;
      }
      this.number = Number(this.number) - Number(step);
    },
    handlePlus() {
      let { btn1_show, step, max_num } = this.info;
      if (btn1_show == 102) return;
      if (Number(this.number) >= Number(max_num)) {
        this.$message.error("已达购买上限");
        return;
      }
      this.number = Number(this.number) + Number(step);
    },
    blur() {
      if (this.info.step != 1) {
        let num = Number(this.number) / Number(this.info.step);
        if (num % 1 !== 0) {
          this.number = Number(this.info.step) * (parseInt(num) + 1);
        }
      }
      if (Number(this.number) <= Number(this.info.min_num)) {
        this.number = this.info.min_num;
      }
      if (Number(this.number) >= Number(this.info.max_num)) {
        this.number = this.info.max_num;
      }
    },
    closeDialog(formName) {
      this.$refs[formName].resetFields();
      this.openGoods = false;
    },
    applyGoods() {
      this.openGoods = true;
    },
    lookLicense() {
      if (this.postFlag) {
        this.$api("goods.goodslicense", { erpid: this.info.erpid }).then((res) => {
          if (res.code == 200) {
            this.license = res.data;
            this.postFlag = false;
            setTimeout(() => {
              this.postFlag = true;
            }, 60000);
            if (this.license.length > 0) {
              this.openLicense = true;
            } else {
              this.$message.error("该商品暂时没有相关资质");
            }
          } else if (res.code == 201) {
            this.$message.warning(res.desc);
          }
        });
      } else {
        this.$message.warning("稍后再请求");
      }
    },
    lookDetail(data) {
      window.open(data.qualification_pdf_file, "_blank");
    },
    applyConfirm(formName) {
      this.goodsForm.id = this.goods_id;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api("goods.goodsApply", this.goodsForm).then((res) => {
            if (res.dode == 200) {
              this.$message.success("申请成功");
              this.getdata();
              this.openGoods = false;
              this.closeDialog("goodsForm");
            } else if (res.code == 201) {
              // this.openGoods = false;
              this.closeDialog("goodsForm");
            }
          });
        }
      });
    },
    enterHandler() {
      this.topShow = true;
      this.rShow = true;
    },
    moveHandler(event) {
      // 鼠标的坐标位置
      let x = event.offsetX;
      let y = event.offsetY;
      // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
      let topX = x - 100 < 0 ? 0 : x - 100;
      let topY = y - 100 < 0 ? 0 : y - 100;
      // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
      if (topX > 150) {
        topX = 150;
      }
      if (topY > 150) {
        topY = 150;
      }
      // 通过 transform 进行移动控制
      this.topStyle.transform = `translate(${topX}px,${topY}px)`;
      this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
    },
    outHandler() {
      this.topShow = false;
      this.rShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background-color: #fff;
  padding: 15px;
  margin-top: 15px;
  display: flex;
  .left {
    width: 350px;
    margin-right: 15px;
    float: left;
    position: relative;
    .img_box {
      width: 350px;
      height: 350px;
      border: 1px solid #dddddd;
      border-radius: 4px;
    }
    .img_top {
      width: 200px;
      height: 200px;
      background-color: #e8e7e7;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
    }
    .maskTop {
      width: 350px;
      height: 350px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
    .img_right_big {
      margin-left: 360px;
      width: 350px;
      height: 350px;
      border: 1px solid #dddddd;
      position: absolute;
      overflow: hidden;
      top: 0;
      background-color: #fff;
      .rightImg {
        display: inline-block;
        width: 700px !important;
        height: 700px !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
      }
    }
    .thumb_wrap {
      width: 350px;
      overflow: hidden;
      .thumb_box {
        display: flex;
        margin-top: 15px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(40, 44, 52, 0.1);
          border: 3px solid transparent;
          border-radius: 7px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(40, 44, 52, 0.1);
        }
        .item {
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          border: 1px solid #dddddd;
          border-radius: 2px;
          margin-right: 10px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .tip {
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #ff4c4c;
    }
  }
  .right {
    flex: 1;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .pr_box {
      width: 800px;
      background: #f5f7fb;
      font-size: 15px;
      line-height: 36px;
      color: #999;
      margin: 15px 0 0px;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .price {
        font-size: 14px;
        color: #ff4c4c;
        margin-left: 10px;
        span {
          font-size: 24px;
          font-weight: bold;
        }
        .huaxian {
          margin-left: 3px;
          font-size: 17px;
          color: #999;
          font-weight: 400;
          text-decoration: line-through;
        }
      }
    }
    .retail_price{
      margin: 15px 0;
      padding: 0 20px;
    }
    .time-box {
      width: 800px;
      height: 36px;
      // background: #f3f3f3;
      font-size: 16px;
      line-height: 36px;
      color: #ff0000;
      margin: 15px 0;
      padding-left: 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    .tag-box {
      width: 100%;
      height: auto;
      display: flex;
      padding-left: 20px;
      flex-direction: row;
      margin-top: 15px;
      .tag {
        color: #fff;
      }
    }
    .active-red {
      color: #f04844;
    }
    .p_title {
      height: 17px;
      border-radius: 4px;
      border: 1px solid #f04844;
      color: #f04844;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2px 6px;
    }
    .detail {
      color: #999;
      line-height: 2;
      margin-left: 20px;
      .flex {
        display: flex;
        div:first-child {
          width: 300px;
        }
      }
      span {
        color: #333333;
      }
    }
    .number_box {
      margin-left: 10px;
      background-color: #fff;
      width: 91px;
      height: 26px;
      border: 1px solid #dddddd;
      border-radius: 2px;
      display: flex;
      .numleft {
        color: #ddd;
      }
      .numleft,
      .numright {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .num {
        width: 36px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        ::v-deep .el-input__inner {
          padding: 0 4px;
          height: 26px;
          border: none;
          text-align: center;
        }
      }
    }
    .btn {
      width: 100px;
      height: 36px;
      line-height: 36px;
      background: @themeColor;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .red-btn {
      background-color: #f56c6c;
    }
    .green-btn {
      background-color: #198c8b;
    }
    .blue-btn {
      background-color: #66b1ff;
    }
    .gray-btn {
      width: 100px;
      height: 36px;
      line-height: 36px;
      background: #a39e9e;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.main_wrap {
  margin: 10px 0;
  display: flex;
  .hot {
    width: 200px;
    // height: 580px;
    background: #ffffff;
    border-radius: 4px;
    margin-right: 10px;
    .title {
      height: 40px;
      background: #eeeeee;
      border-radius: 4px 4px 0 0;
      font-size: 16px;
      color: #333333;
    }
    .goods_wrap {
      padding: 10px;
      line-height: 1.8;
      span {
        color: #ff4c4c;
      }
      .flex_column {
        padding-bottom: 10px;
        border-bottom: 1px dashed #ddd;
        margin-bottom: 10px;
      }
      .goodsimg {
        width: 180px;
        height: 160px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        margin-bottom: 10px;
      }
    }
  }
  .main {
    width: 990px;
    min-height: 580px;
    background: #ffffff;
    border-radius: 4px;
    .tab {
      height: 40px;
      background: #eeeeee;
      border-radius: 4px 4px 0px 0px;
      border-bottom: 1px solid @themeColor;
      display: flex;
      line-height: 40px;
      .item {
        width: 100px;
        text-align: center;
        color: #333333;
        font-size: 16px;
        cursor: pointer;
        &.active {
          background-color: @themeColor;
          border-radius: 4px 4px 0px 0px;
          color: #fff;
        }
      }
    }
    .cont {
      padding: 10px;
      width: 970px;
      // width: calc(100% - 20px);
      // background-color: cadetblue;
      overflow-x: auto;
    }
  }
}
.no-goods-box {
  text-align: center;
}
.pop-list {
  // width: 100%;
  height: 34px;
  line-height: 34px;
  margin-bottom: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  align-items: center;
}
</style>
